import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { history } from "./helpers";
const ProductList = React.lazy(() =>
  import("./containers/ProductListing/productListing.jsx")
);
const ProductLocator = React.lazy(() =>
  import("./containers/productLocator/productLocator.jsx")
);
const GlobalLoader = React.lazy(() =>
  import("../src/components/loader/loader")
);

function App() {
  return (
    <div className="main-container pl">
      <GlobalLoader />
      <div className="pl__container">
        <div className="col-md-12">
          <Router history={history}>
            <Switch>
              {["", ""].map((path, index) => (
                <Route path={path} exact component={ProductList} key={index} />
              ))}
              <Route path="/product-locator" component={ProductLocator} />
            </Switch>
          </Router>
        </div>
      </div>
    </div>
  );
}

export { App };
