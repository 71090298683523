import React, { Suspense } from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { store } from "./store/store";
import { App } from "./App";
import Wrapper from "./core/language-wrapper";
import "./web.config";
const BuyNowTrigger = React.lazy(() =>
  import("../src/containers/BuyNowPopup/BuyNowPopupTrigger")
);

const MapPopupTrigger = React.lazy(() =>
  import("../src/containers/BuyNowPopup/MapPopupTrigger")
);

// if (document.getElementsByTagName("bsi-product-locator").length) {
//   let getTheme = document.getElementsByTagName("bsi-product-locator")[0];
//   let theme = getTheme.getAttribute("theme");
//   if (theme) {
//     import("./brandtheme/" + theme + '.scss');
//   }
//   console.log('theme==', theme);
// }

class MyElement extends HTMLElement {
  connectedCallback() {
    ReactDOM.render(
      <Suspense fallback={<div>Loading...</div>}>
        <Wrapper>
          <Provider store={store}>
            <App />
          </Provider>
        </Wrapper>
      </Suspense>,
      this
    );
  }

  disconnectedCallback() {
    ReactDOM.unmountComponentAtNode(this);
  }
}

class buyNowElement extends HTMLElement {
  connectedCallback() {
    this.addEventListener(
      "click",
      function () {
        var pid;
        pid = this.getAttribute("product");
        let pElem = document.createElement("div");
        let shadowRoot = this.attachShadow({ mode: "open" }).appendChild(pElem);
        ReactDOM.render(
          <Suspense fallback={<div>Loading...</div>}>
            <Wrapper>
              <Provider store={store}>
                <BuyNowTrigger pid={pid} />
              </Provider>
            </Wrapper>
          </Suspense>,
          shadowRoot
        );
      },
      true
    );
  }
  disconnectedCallback() {
    ReactDOM.unmountComponentAtNode(this);
  }
}
class mapElement extends HTMLElement {
  connectedCallback() {
    this.addEventListener(
      "click",
      function () {
        var pid;
        pid = this.getAttribute("product");
        var tab = this.getAttribute("tab");
        let pElem = document.createElement("div");
          let shadowRoot = this.appendChild(pElem);
          ReactDOM.render(
            <Suspense fallback={<div className="pl-fallback-loading">Loading...</div>}>
              <Wrapper>
                <Provider store={store}>
                  <MapPopupTrigger pid={pid} tab={tab} />
                </Provider>
              </Wrapper>
            </Suspense>,
            shadowRoot
          );
        // }
      },
      true
    );
  }
  disconnectedCallback() {
    ReactDOM.unmountComponentAtNode(this);
  }
}

window.customElements.define("bsi-product-locator", MyElement);
window.customElements.define("bsi-locator-buy-button", buyNowElement);
window.customElements.define("bsi-map-popup", mapElement);
